require('../../bootstrap.js');
require('../../chains.js');

require('./global.js');


//import Swal from 'sweetalert2';
import toastr from 'toastr';

const jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;

const showAlert = (message, status, timer = 2000) => {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": timer,
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }

    toastr[status](message)
    console.log('Alerted, timer', timer);
};


document.addEventListener('showAlert', event => {
    let timer = event.detail.timer ? event.detail.timer : 3000;
    showAlert(event.detail.message, event.detail.status, timer);
})

document.addEventListener('taskHubTimer', event => {
    timerForTasks(event.detail.id, event.detail.seconds);

})

document.addEventListener('setIframeFullScreen', event => {
    setIframeFullScreen();
})


const setIframeFullScreen = () => {
    console.log('Set full screen iframe');
    var iframe = document.querySelector(
        ".game-mode-page__gamezone__gamewindow iframe"
    );
    if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
    } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
    } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
    }
}

const showSlider = (id) => {
    window.sliders.forEach(element => {
        if (Object.keys(element).includes(id)) {
            element[id]();
        }
    });
}

Livewire.hook('element.initialized', (el, component) => {
    if (el.classList.contains('splide__list')) {
        let id = el.getAttribute('id');
        //console.log('Splide initialized', id);
        setTimeout(() => {
            showSlider(id);
        }, 1000);
    }
})

Livewire.hook('element.updated', (el, component) => {
    if (el.classList.contains('splide__list')) {
        let id = el.getAttribute('id');
        // console.log('Splide updated', id);
        setTimeout(() => {
            showSlider(id);
        }, 1000);
    }
    //console.log('Element updated', el);
    if (el.classList.contains('dropdown-popup-component-popup')) {
        initDropDowns();
    }
})

const dispatchEvent = (name, data) => {
    var event; // The custom event that will be created
    if (document.createEvent) {
        event = document.createEvent("HTMLEvents");
        event.initEvent(name, true, true);
        event.eventName = name;
        event.data = data;
        document.dispatchEvent(event);
    } else {
        event = document.createEventObject();
        event.eventName = name;
        event.eventType = name;
        event.data = data;
        document.fireEvent("on" + event.eventType, event);
    }
}


const timerForTasks = (id, seconds) => {
    if (seconds > 0) {
        if (window.timerTaskHub) {
            clearInterval(window.timerTaskHub);
        }
        window.timerTaskHub = setInterval(() => {
            let timerTextWeekly = moment.duration(`${seconds}`, "seconds").hours() + ':'
                + moment.duration(`${seconds}`, "seconds").minutes() + ':'
                + moment.duration(`${seconds}`, "seconds").seconds();
            if (seconds > 86400) {
                timerTextWeekly = moment.duration(`${seconds}`, "seconds").days() + ':' + timerTextWeekly
            }
            seconds -= 1;
            $(`#${id}`).text(timerTextWeekly);
        }, 1000);

        setTimeout(() => {
            clearInterval(timerWeeklyId);
        }, seconds * 1000);
    }
}

const showModal = (id) => {
    Fancybox.show([{
        src: `#${id}`
    }])
}

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    showAlert('Скопировано', 'success');
}

window.showAlert = showAlert;
window.sliders = new Array();
window.showSlider = showSlider;
window.dispatchEvent = dispatchEvent;
window.timerForTasks = timerForTasks;
window.showModal = showModal;
window.copyToClipboard = copyToClipboard;
window.setIframeFullScreen = setIframeFullScreen;
