var $ = require("jquery");

var moment = require("moment");


window._ = require("lodash");
window.$ = $;
window.jQuery = $;
window.moment = moment;

try {
    require("bootstrap");
} catch (e) {}

/**
 * Alpine JS
 */
import Alpine from "alpinejs";

window.Alpine = Alpine;

Alpine.start();

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.laravel_echo_port = process.env.MIX_LARAVEL_ECHO_PORT;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// Initiate the Pusher JS library
window.Pusher = require("pusher-js");

import Echo from "laravel-echo";

// window.Echo = new Echo({
//     key: process.env.MIX_PUSHER_APP_KEY,
//     broadcaster: 'pusher',
//     cluster: "eu",
//     forceTLS: true,
// });

//var channel = window.Echo.channel(`payment-data.${window.user}`);

// channel.listen(`payment-data.${window.user}`, function (data) {
//     console.log('PaymentData', data);
// })
