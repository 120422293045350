(() => {
    "use strict";

    const mainblock = document.querySelector(".main");

    // notifications toggle

    const notifications = document.getElementById("notifications");
    const toggleNotifications = document.getElementById("toggle-notifications");
    const closeNotifications = document.getElementById("notifications-close");
    // const markRead = document.getElementsByClassName(
    //     "notifications__item__actions__markread"
    // );
    // const markReadAll = document.getElementById("notifications__footer");
    // const notificationsItems = document.getElementsByClassName(
    //     "notifications__item"
    // );

    if (toggleNotifications) {
        toggleNotifications.addEventListener("click", () => {
            notifications.classList.toggle("notifications-showed");
            mainblock.classList.toggle("opened-notifications");
        });
    }

    if (closeNotifications) {
        closeNotifications.addEventListener("click", () => {
            notifications.classList.remove("notifications-showed");
            mainblock.classList.remove("opened-notifications");
        });
    }

    window.addEventListener("click", (e) => {
        if (document.querySelector("#toggle-notifications")) {
            if (
                !document.querySelector("#notifications").contains(e.target) &&
                !document.querySelector("#toggle-notifications").contains(e.target)
            ) {
                notifications.classList.remove("notifications-showed");
                mainblock.classList.remove("opened-notifications");
            }
        }
    });

    // for (let i = 0; i < markRead.length; i++) {
    //     markRead[i].onclick = function () {
    //         this.closest(".notifications__item").classList.add(
    //             "notification-hidden"
    //         );
    //     };
    // }

    // markReadAll.addEventListener("click", () => {
    //     for (let i = 0; i < notificationsItems.length; i++) {
    //         notificationsItems[i].classList.add("notification-hidden");
    //     }
    // });

    // chat and sidebar toggle

    const mobileNav = document.querySelector("#tapbar-menu");
    const toggleNav = document.querySelector("#toggle-nav");
    const sidebar = document.querySelector("#sidebar");
    const cash = document.querySelector(".sb-cash img");
    // const wheel = document.querySelector(".sb-wheel img");
    const navImg = document.querySelector("#header-nav-arrow");

    if (toggleNav) {
        toggleNav.addEventListener("click", () => {
            sidebar.classList.toggle("closed");
            mobileNav.classList.toggle("active");

            if (sidebar.classList.contains("closed")) {
                cash.setAttribute(
                    "src",
                    "/betrabbit/v3/assets/sidebar/cash-sidebar-closed.png"
                );
                // wheel.setAttribute(
                //     "src",
                //     "./betrabbit/v3/assets/sidebar/wheel-sidebar-closed.png"
                // );
                navImg.classList.add("header-nav-arrow-closed");
                mainblock.classList.remove("opened-sidebar");
            } else {
                cash.setAttribute("src", "/betrabbit/v3/assets/sidebar/cash.png");
                // wheel.setAttribute("src", "./betrabbit/v3/assets/sidebar/wheel.svg");
                navImg.classList.remove("header-nav-arrow-closed");
                mainblock.classList.add("opened-sidebar");
            }
        });
    }

    if (mobileNav) {
        mobileNav.addEventListener("click", () => {
            sidebar.classList.toggle("closed");
            mobileNav.classList.toggle("active");

            toggleChatMobile.classList.remove("active");
            chat.classList.add("closed");
            mainblock.classList.remove("opened-chat");

            if (sidebar.classList.contains("closed")) {
                cash.setAttribute(
                    "src",
                    "/betrabbit/v3/assets/sidebar/cash-sidebar-closed.png"
                );
                // wheel.setAttribute(
                //     "src",
                //     "./betrabbit/v3/assets/sidebar/wheel-sidebar-closed.png"
                // );
                navImg.classList.add("header-nav-arrow-closed");
                mainblock.classList.remove("opened-sidebar");
            } else {
                cash.setAttribute("src", "/betrabbit/v3/assets/sidebar/cash.png");
                // wheel.setAttribute("src", "./betrabbit/v3/assets/sidebar/wheel.svg");
                navImg.classList.remove("header-nav-arrow-closed");
                mainblock.classList.add("opened-sidebar");
            }
        });
    }

    const toggleChatMobile = document.querySelector("#tapbar-chat");
    const toggleChat = document.querySelector("#chat-toggle");
    const chatClose = document.querySelector("#chat-close");
    const chat = document.querySelector("#chat");

    if (toggleChat) {
        toggleChat.addEventListener("click", () => {
            // chat.classList.toggle("closed");
            mainblock.classList.toggle("opened-chat");
        });
    }
    if (toggleChatMobile) {
        toggleChatMobile.addEventListener("click", () => {
            toggleChatMobile.classList.toggle("active");
            // chat.classList.toggle("closed");
            mainblock.classList.toggle("opened-chat");

            sidebar.classList.add("closed");
            mobileNav.classList.remove("active");
        });
    }
    if (chatClose) {
        chatClose.addEventListener("click", () => {
            toggleChatMobile.classList.toggle("active");
            // chat.classList.add("closed");
            mainblock.classList.remove("opened-chat");
        });
    }

    // const chatCloseFunc = () => {
    //     toggleChatMobile.classList.toggle("active");
    //     chat.classList.add("closed");
    //     mainblock.classList.remove("opened-chat");
    // }


    // Search toggle
    const searchField = document.querySelector(
        ".header__center__search__input"
    );
    const searchOpen = document.querySelector("#search-open");
    const searchClose = document.querySelector("#search-curtain");
    const searchRecommend = document.querySelector(".search-recommend");
    const searchResult = document.querySelector(".search-result");

    const searchHandle = (text) => {
        if (text.length > 2) {
            mainblock.classList.add("opened-search");
            searchResult.classList.add("search-show");
            searchRecommend.classList.remove("search-show");
        } else {
            mainblock.classList.add("opened-search");
            searchRecommend.classList.add("search-show");
            searchResult.classList.remove("search-show");
        }
    };

    if (searchField) {
        searchField.addEventListener("focus", (e) => {
            searchHandle(e.target.value);
        });
        searchField.addEventListener("input", (e) => {
            searchHandle(e.target.value);
        });

        searchOpen.addEventListener("click", () => {
            mainblock.classList.add('opened-search');
        });

        searchClose.addEventListener("click", () => {
            mainblock.classList.remove("opened-search");
            searchRecommend.classList.remove("search-show");
            searchResult.classList.remove("search-show");
        });
    }

    window.addEventListener("click", (e) => {
        if (
            !document.querySelector(".search-container").contains(e.target) &&
            !document
                .querySelector(".header__center__search")
                .contains(e.target)
        ) {
            mainblock.classList.remove("opened-search");
        }
    });



    /** Popup */

    Fancybox.bind("[data-fancybox]", {});

    /** Tabs */

    const tabSwitcher = (toggle, content, inner = false) => {
        const tabButtons = !inner
            ? document.querySelectorAll(`#${toggle} .tabs-toggle-button`)
            : document.querySelectorAll(`#${toggle} .tabs-toggle-inner-button`);

        const tabContents = !inner
            ? document.querySelectorAll(`#${content} .tabs-content-tab`)
            : document.querySelectorAll(`#${content} .tabs-content-tab-inner`);

        for (const tabBtn of tabButtons) {
            tabBtn.addEventListener("click", () => {
                for (const tabBtn2 of tabButtons) {
                    tabBtn2.classList.remove("active");
                }

                for (const tabCnt of tabContents) {
                    tabCnt.classList.remove("active");

                    if (
                        tabBtn.getAttribute("data-tab") ===
                        tabCnt.getAttribute("data-tab")
                    ) {
                        tabBtn.classList.add("active");
                        tabCnt.classList.add("active");
                    }
                }
            });
        }
    };

    tabSwitcher("wallet-tabs-toggle", "wallet-tabs-content");
    tabSwitcher("transactions-tabs-toggle", "transactions-tabs-content");
    tabSwitcher(
        "wallet-tabs-deposit-toggle",
        "wallet-tabs-deposit-content",
        true
    );
    tabSwitcher(
        "wallet-tabs-withdraw-toggle",
        "wallet-tabs-withdraw-content",
        true
    );
    tabSwitcher("tabs-currency-toggle", "tabs-currency-content");

    // dropdown popup

    const dropdownTogglers = document.querySelectorAll(
        ".dropdown-popup-component .dropdown-popup-toggler"
    );
    const dropdownPopups = document.querySelectorAll(
        ".dropdown-popup-component .dropdown-popup"
    );
    const dropdownArrows = document.querySelectorAll(
        ".dropdown-popup-component .dropdown-popup-toggler .dropdown-arrow"
    );

    if (dropdownTogglers && dropdownPopups && dropdownArrows) {
        for (const toggler of dropdownTogglers) {
            toggler.addEventListener("click", () => {
                for (const popup of dropdownPopups) {
                    if (
                        toggler.getAttribute("data-dropdown") ===
                        popup.getAttribute("data-dropdown")
                    ) {
                        popup.classList.toggle("dropdown-popup-active");

                        for (const arrow of dropdownArrows) {
                            if (
                                toggler.getAttribute("data-dropdown") ===
                                arrow.getAttribute("data-dropdown")
                            ) {
                                arrow.classList.toggle("dropdown-arrow-up");
                            }
                        }
                    }
                }
            });
        }
    }

    const useClickOutsidePopup = (popupId, popup, arrow) => {
        window.addEventListener("click", (e) => {
            console.log("popupId", popupId);
            if (
                document.querySelector(popupId) &&
                !document.querySelector(popupId).contains(e.target)
            ) {
                document
                    .querySelector(popup)
                    .classList.remove("dropdown-popup-active");

                if (document.getElementById(arrow)) {
                    document
                        .getElementById(arrow)
                        .classList.remove("dropdown-arrow-up");
                }
            }
        });
    };

    useClickOutsidePopup(
        "#filter",
        "#filter .filter",
        "arrow-filter"
    );
    // useClickOutsidePopup(
    //     "#user-wallet",
    //     "#user-wallet .user-wallet",
    //     "arrow-user-wallet"
    // );
    useClickOutsidePopup(
        "#user-account",
        "#user-account .user-account",
        "arrow-user-account"
    );
    // useClickOutsidePopup(
    //     "#select-network",
    //     "#select-network .select-network",
    //     "arrow-select-network"
    // );
    useClickOutsidePopup(
        "#emoji",
        "#emoji .emoji",
        ""
    );
    useClickOutsidePopup(
        "#language",
        "#language .language",
        ""
    );

    // dropdown select

    const dropdownSelect = document.getElementsByClassName(
        "dropdown-popup__select"
    );

    for (let i = 0; i < dropdownSelect.length; i++) {
        dropdownSelect[i].onclick = function (e) {
            let component = this.closest(".dropdown-popup-component");
            component.querySelector(".dropdown-popup-toggler").click();
            component.querySelector("input").value =
                this.getAttribute("data-value");
            component.querySelector(
                ".dropdown-popup-toggler span"
            ).textContent = this.getAttribute("data-value");
        };
    }

    // Splide transactions

    const transactions = document.getElementById("transactions");
    const transactions_splide = document.querySelector(".transactions-splide");

    const sliderCustomControls = (controls, slider) => {
        const controlPrev = document.querySelector(`#${controls[0]}`);
        const controlNext = document.querySelector(`#${controls[1]}`);
        const sliderPrev = document.querySelector(
            `#${slider} button.splide__arrow.splide__arrow--prev`
        );
        const sliderNext = document.querySelector(
            `#${slider} button.splide__arrow.splide__arrow--next`
        );

        if (sliderPrev.disabled) {
            controlPrev.disabled = true;
        } else {
            controlPrev.disabled = false;
        }
        if (sliderNext.disabled) {
            controlNext.disabled = true;
        } else {
            controlNext.disabled = false;
        }

        if (controlPrev && controlNext && sliderNext && sliderPrev) {
            controlPrev.addEventListener("click", () => sliderPrev.click());
            controlNext.addEventListener("click", () => sliderNext.click());
        }
    };

    if (transactions && transactions_splide) {
        const transactionsSplide = new Splide(".transactions-splide", {
            type: "loop",
            perMove: 1,
            pagination: false,
        });

        transactionsSplide.mount();
        sliderCustomControls(
            ["transactions-list-prev", "transactions-list-next"],
            "transactions-list-slider"
        );
    }

    // const searchsplide = new Splide(".search-splide", {
    //     type: "slide",
    //     perPage: 6,
    //     perMove: 1,
    //     pagination: false,
    //     gap: "14px",
    //     breakpoints: {
    //         320: {
    //             perPage: 1,
    //         },
    //         440: {
    //             perPage: 2,
    //         },
    //         600: {
    //             perPage: 3,
    //         },
    //         767: {
    //             perPage: 4,
    //         },
    //         1200: {
    //             perPage: 4,
    //         },
    //     },
    // });

    // searchsplide.mount();

    // sliderCustomControls(["search-prev", "search-next"], "search-slider");

    // const searchsplide2 = new Splide(".search-splide2", {
    //     type: "slide",
    //     perPage: 6,
    //     perMove: 1,
    //     pagination: false,
    //     gap: "14px",
    //     breakpoints: {
    //         320: {
    //             perPage: 1,
    //         },
    //         440: {
    //             perPage: 2,
    //         },
    //         600: {
    //             perPage: 3,
    //         },
    //         767: {
    //             perPage: 4,
    //         },
    //         1200: {
    //             perPage: 4,
    //         },
    //     },
    // });

    // searchsplide2.mount();

    // sliderCustomControls(["search-prev2", "search-next2"], "search-slider2");

    // const sliderCustomControlsState = (controls, slider, splide) => {
    //     const controlPrev = document.querySelector(`#${controls[0]}`);
    //     const controlNext = document.querySelector(`#${controls[1]}`);
    //     const sliderPrev = document.querySelector(
    //         `#${slider} button.splide__arrow.splide__arrow--prev`
    //     );
    //     const sliderNext = document.querySelector(
    //         `#${slider} button.splide__arrow.splide__arrow--next`
    //     );
    //     splide.on("arrows:updated", () => {
    //         if (sliderPrev.disabled) {
    //             controlPrev.disabled = true;
    //         } else {
    //             controlPrev.disabled = false;
    //         }
    //         if (sliderNext.disabled) {
    //             controlNext.disabled = true;
    //         } else {
    //             controlNext.disabled = false;
    //         }
    //     });
    // };
    // sliderCustomControlsState(
    //     ["search-prev", "search-next"],
    //     "search-slider",
    //     searchsplide
    // );
    // sliderCustomControlsState(
    //     ["search-prev2", "search-next2"],
    //     "search-slider2",
    //     searchsplide2
    // );
})();
